import React from 'react';
import {Button, Divider, Flex, Input, Select, Space, Spin, Table} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {BasePageType} from "../../common/listpage/BaseListPageType";
import {BaseDhPageDate} from "../../dhHospital/common/DhPageCommon";
import {Link} from "react-router-dom";
import encryptData from "../../common/crypto";
import {addParams, NUMBER_STR, STRING_STR} from "../../cooperate/bill/page/ListCommon";
import {SignStatusArr} from "../util/TroupeArtistUtil";
import * as XLSX from "xlsx";
import Fetch from "../../common/FetchIt";
import styles from "../../cooperate/add/ServiceUser.module.css";
import {BoneRoleType} from "../util/TroupeArtistType";

type User = { name: string | '', msg: string | '', id: number | 0, artistId: number | 0 };
type HospitalPatientType = BasePageType & {
    searchData: {
        name: undefined,
        signStatus: undefined,
        page: 0
    }
    btnQ: BoneRoleType,
    statusListArr: any[] | undefined
}
const defData: HospitalPatientType = {
    ...BaseDhPageDate,
    searchData: {
        name: undefined,
        signStatus: undefined,
        page: 0
    },
    btnQ: {},
    statusListArr: []
}

class TroupSignListClass extends BaseListPage {

    state: HospitalPatientType = {
        ...defData,
        btnQ: {}
    };

    componentDidMount() {
        // BoneSelectStatus([105, 106, 107], this)
        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.troup.sign.query_page}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("signStatus", searchData.signStatus, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(defData.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {

        this.loadData();
    }

    importData = (file: any) => {
        this.setState({
            importLoading: true,
            tip: "数据导入中"
        });
        // 获取上传的文件对象
        const {files} = file.target;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                // @ts-ignore
                const {result} = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, {type: 'binary'});
                let data: any = []; // 存储获取到的数据
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break; // 如果只取第一张表，就取消注释这行
                    }
                }
                const param = this.buildData(data);
                Fetch.postJSON(
                    `${API.troup.sign.import_data}`, param
                ).then((data) => {
                    this.loadData();
                    this.setState({
                        importLoading: false,
                    });
                }).catch((err)=>{
                    this.setState({
                        importLoading: false,
                    });
                });
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                this.setState({
                    importLoading: false,
                });
                return;
            } finally {

            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    }

    buildData = (dates: any) => {

        const result = [];
        for (let i = 0; i < dates.length; i++) {
            const item = dates[i];
            result.push({
                "name": item.姓名,
                "rongTitle": item.艺术团荣称,
                "identity": item.身份,
                "idNumber": item.身份证号,
                "phone": item.联系电话,
                "province": item.省,
                "city": item.市,
                "district": item.区,
                "address": item.地址,
                "areaStr": item.省市区,
                "certificateDate": item.发证时间,
                "orProvince": item.籍贯省份,
                "orCity": item.籍贯市,
                "orDistrict": item.籍贯区,
                "orAreaStr": item.籍贯
            })
        }
        console.log(result)
        return result;
    }

    clickFileInput = () => {
        // @ts-ignore
        document.getElementById('fileInput').click()
    }

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content, selectId,
            statusListArr
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '艺术团荣称',
                dataIndex: 'rongTitle',
                align: `center`,
            },
            {
                title: '验证码',
                dataIndex: 'captcha',
                align: `center`,
            },
            {
                title: '验证次数',
                dataIndex: 'successCount',
                align: `center`,
            },
            {
                title: '签名时间',
                dataIndex: 'signDate',
                align: `center`,
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (value: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            {btnQ.ROLE_220001 && (
                                <Link to={`/troup/guru/detail?id=${encryptData(record.artistId + "")}`}>
                                    详情
                                </Link>
                            )}
                        </Space>
                    );
                },
            },
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Flex justify={"space-between"}>
                            <Space onKeyDown={this.handleKeyDown} wrap>
                                <Input
                                    placeholder="姓名"
                                    value={searchData.name}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, name: e.target.value})
                                    }
                                    }
                                />
                                <Select
                                    style={{width: 220}}
                                    placeholder="状态"
                                    optionFilterProp="children"
                                    options={SignStatusArr}
                                    value={searchData.signStatus}
                                    allowClear={true}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, signStatus: e})
                                    }}
                                />
                                <Button onClick={this.loadData}>
                                    搜索
                                </Button>
                                <Button onClick={() => this.reset()}>重置</Button>
                            </Space>
                            {btnQ.ROLE_220002 && (
                                <Button type={"primary"} onClick={() => this.clickFileInput()}>导入艺术家</Button>
                            )}
                        </Flex>
                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <input id="fileInput" className={styles.fileUploader} type='file'
                       accept='.xlsx, .xls'
                       onChange={this.importData}/>
            </>
        );
    }

}

export default function TroupSignList() {

    return (

        <App selectedKeys={
            ['TroupSignList']
        }
             openKeys="TROUP">
            <TroupSignListClass/>
        </App>
    );
}
