import React from 'react';
import {Button, Input, message, Modal, Select, Space, Spin, Table} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import common from "../../common/common";
import encryptData, {decryptData} from "../../common/crypto";
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {BaseDhPageDate} from "../common/DhPageCommon";
import {DhSelectStatus, dhStatusColorObj, dhStatusObj} from "../common/DhDictData";
import {addParams, NUMBER_STR, STRING_STR} from "../../cooperate/bill/page/ListCommon";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {BasePageType} from "../../common/listpage/BaseListPageType";

type User = {};

const confirm = Modal.confirm;

type ReimbursedBatchDetailType = BasePageType & {

    searchData: {
        name: string | undefined,
        idCard: string | undefined,
        payee: string | undefined
        status: string | undefined,
    },
    selectId: number | 0,
    btnQ: any,
    modalTitle: string | '',
    statusListArr: [] | undefined
}

const ReimbursedBatchDetailData: ReimbursedBatchDetailType = {
    ...BaseDhPageDate,
    searchData: {
        name: undefined,
        idCard: undefined,
        payee: undefined,
        status: undefined,
        page: 0
    },
    selectId: 0,
    btnQ: {},
    modalTitle: '',
    statusListArr: []
}

class ReimbursedBatchDetailListClass extends BaseListPage {

    state: ReimbursedBatchDetailType = {
        ...ReimbursedBatchDetailData,
        btnQ: {},
        modalTitle: '导入结果'
    };

    componentDidMount() {
        super.componentDidMount();
        DhSelectStatus([115, 116, 114], this);
    }

    downloadTotal = (bId: number) => {

        let that = this;
        confirm({
            title: `是否确认导出？`,
            onOk() {
                const code = common.getQuery('code')
                that.setState({
                    importLoading: true,
                    tip: "数据导出中"
                });
                let url = `${API.dentistry.dh_reimbursed_batch.do_download}?bId=${bId}`;
                Fetch.get(url).then((data) => {
                    if (!data) {
                        message.error('下载失败，请联系管理员');
                        that.setState({
                            importLoading: false,
                        });
                        return
                    }
                    common.downloadFile(code + ".xls", API.domain + data, (callback: any) => {
                        if (callback === true) {
                            message.success('处理完成');
                        } else {
                            message.error('下载失败，请联系管理员');
                        }
                        that.setState({
                            importLoading: false,
                        });
                    });
                });
            },
        });
    };

    loadData = () => {
        const bId = decryptData(common.getQuery('bId'));
        const {pageSize, searchData} = this.state;
        let url = `${API.dentistry.dh_reimbursed_batch.detail_query_page}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("bId", bId, NUMBER_STR);
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("payee", searchData.payee, STRING_STR);
        url = url + addParams("idCard", searchData.idCard, STRING_STR);
        url = url + addParams("status", searchData.status, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                window.location.reload()
            }
        );
    }

    render() {
        //
        const bId = decryptData(common.getQuery('bId'));
        const encryptBid = common.getQuery('bId');
        const {
            searchData, pageSize, totalElements,
            content, btnQ, statusListArr
        } = this.state;
        //姓名 身份证号 收款人 报销金额  关联医院 状态 创建时间 操作
        const columns: ColumnsType<User> = [
            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
                width: 50,
                render: (text) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{text}</span>
                    );
                },
            },
            {
                title: '身份证号',
                dataIndex: 'idCard',
                align: `center`,
                width: 70,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '收款人',
                dataIndex: 'payee',
                align: `center`,
                width: 50,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '报销金额',
                dataIndex: 'subsidy',
                align: `center`,
                width: 80,
                render(value,) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },

            {
                title: '关联医院',
                dataIndex: 'hospitalName',
                align: `center`,
                width: 70,
                render(value) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: `center`,
                width: 100,
                render(value) {
                    return <span
                        style={{wordBreak: 'break-all', color: dhStatusColorObj[value]}}>{dhStatusObj[value]}</span>;
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
                width: 100,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                width: 120,
                render: (text, record: any) => {
                    return (
                        <Space>
                            {/*TODO 权限*/}
                            <Link
                                to={`/dh/patient_application/detail?id=${encryptData(record.id + "")}&snap=${encryptData(record.batchDetailStatus === 3 ? "true" : "false")}&bId=${encryptBid}`}>
                                详情
                            </Link>
                        </Space>
                    );
                },
            }
        ];
        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space onKeyDown={this.handleKeyDown} wrap>
                            <Input
                                placeholder="姓名"
                                value={this.state.searchData.name}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, name: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="身份证号"
                                value={this.state.searchData.idCard}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, idCard: e.target.value})
                                }
                                }
                            />
                            <Input
                                placeholder="收款人"
                                value={this.state.searchData.payee}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, payee: e.target.value})
                                }
                                }
                            />
                            <Select
                                style={{width: 220}}
                                placeholder="状态"
                                optionFilterProp="children"
                                options={statusListArr}
                                value={this.state.searchData.status}
                                onChange={(e) => {
                                    this.changeCondition({page: 0, status: e})
                                }}
                            />

                            <Button onClick={this.loadData}>
                                搜索
                            </Button>
                            <Button onClick={() => this.reset(ReimbursedBatchDetailData.searchData)}>重置</Button>
                            {btnQ.ROLE_146008 && (
                                <Button onClick={() => this.downloadTotal(bId)}>
                                    下载未报销名单
                                </Button>
                            )}
                        </Space>

                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            scroll={{x: 1500}}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }
}

export default function ReimbursedBatchDetailList() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <ReimbursedBatchDetailListClass/>
        </App>
    );
}
