import React from 'react';
import {Button, Divider, Input, Select, Space, Spin, Table} from "antd";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import BaseListPage from "../../common/listpage/BaseListPage";
import API from "../../common/api";
import {ColumnsType} from "antd/es/table";
import {BaseDhPageDate} from "../../dhHospital/common/DhPageCommon";
import App from '../../../App';
import {BasePageType} from "../../common/listpage/BaseListPageType";
import encryptData from "../../common/crypto";
import {Link} from "react-router-dom";
import {GroupType, SubSource, SubSourceArr} from "../ExamCommonData";
import {addParams, STRING_STR} from "../../cooperate/bill/page/ListCommon";

type User = { paperCode: string, phoneId: number };
type ExamPaperListType = BasePageType & {
    searchData: {
        page: number | 0,
        name: string | '',
        phone: string | '',
        unit: string | '',
        subSource: string | undefined
    }
}
export const DEFAULT: ExamPaperListType = {
    ...BaseDhPageDate,
    searchData: {
        page: 0,
        name: '',
        phone: '',
        unit: '',
        subSource: undefined
    }
}

class ExamAnswerUserListClass extends BaseListPage {

    state: ExamPaperListType = {
        ...DEFAULT,
        btnQ: {}
    }

    componentDidMount() {

        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.exam.answer.query_list}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("userName", searchData.name, STRING_STR);
        url = url + addParams("userPhone", searchData.phone, STRING_STR);
        url = url + addParams("unit", searchData.unit, STRING_STR);
        url = url + addParams("subSource", searchData.subSource, STRING_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(BaseDhPageDate.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }


    render() {
        const {
            btnQ, searchData, content, pageSize, totalElements
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'userName',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}</span>
                        </div>
                    );
                },
            },
            {
                title: '手机号',
                dataIndex: 'userPhone',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}</span>
                        </div>
                    );
                },
            },

            {
                title: '参与组',
                align: `center`,
                dataIndex: 'userType',
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{GroupType[text]}</span>
                        </div>
                    );
                },
            },
            {
                title: '参与平台',
                align: `center`,
                dataIndex: 'subSource',
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{SubSource[text]}</span>
                        </div>
                    );
                },
            },
            {
                title: '单位',
                align: `center`,
                dataIndex: 'unit',
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}</span>
                        </div>
                    );
                },
            }, {
                title: '操作',
                align: `center`,
                dataIndex: 'unit',
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            <Link to={`/exam/answer_user/detail?id=${encryptData(record.phoneId + "")}`}>
                                查看详情
                            </Link>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction="vertical" style={{width: '100%'}}>
                            <Space onKeyDown={this.handleKeyDown} wrap>
                                <Input
                                    placeholder="姓名"
                                    value={searchData.name}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, name: e.target.value})
                                    }
                                    }
                                />
                                <Input
                                    placeholder="手机号"
                                    value={searchData.phone}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, phone: e.target.value})
                                    }
                                    }
                                />
                                <Input
                                    placeholder="参与组"
                                    value={searchData.unit}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, unit: e.target.value})
                                    }
                                    }
                                />
                                <Select
                                    style={{width: 220}}
                                    placeholder="参与平台"
                                    optionFilterProp="children"
                                    options={SubSourceArr}
                                    value={searchData.subSource}
                                    allowClear={true}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, subSource: e})
                                    }}
                                />
                                <Button onClick={this.loadData}>
                                    搜索
                                </Button>
                                <Button onClick={() => this.reset()}>重置</Button>
                            </Space>
                        </Space>


                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function ExamAnswerUserList() {

    return (

        <App selectedKeys={
            ['AnswerUser']
        }
             openKeys="EXAM">
            <ExamAnswerUserListClass/>
        </App>
    )
}
