import React from 'react';
import {Button, Divider, Input, message, Modal, Popconfirm, Popover, Select, Space, Spin, Table, Tooltip} from 'antd';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import encryptData from '../../common/crypto';
import BaseListPage from "../../common/listpage/BaseListPage";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {ApplicationList, ApplicationListType, BaseDhPageDate} from "../common/DhPageCommon";
import {genderArr} from "../../cooperate/util/AierCommon";
import {BookingTime, dhStatusArray, dhStatusColorObj, dhStatusObj} from "../common/DhDictData";
import {DatabaseOutlined, HomeOutlined, InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import styles from "../../cooperate/add/ServiceUser.module.css";
import Fetch from "../../common/FetchIt";
import common from "../../common/common";
import * as XLSX from "xlsx";
import createModal from "../../common/createModal";
import ImportResultModal from "../../common/modal/ImportResultModal";
import DHospitalBookingModelImport from "../hospitalPatient/DHospitalBookingModel";
import {addParams, NUMBER_STR, STRING_STR} from "../../cooperate/bill/page/ListCommon";
import DhRecordOperationsDetail from "../patientDetail/DhRecordOperationsDetail";

type User = {
    accountRefine: boolean,
    name: string,
    id: number
};

const {confirm} = Modal;
const ImportResult = createModal(ImportResultModal);
const DHospitalBookingModel = createModal(DHospitalBookingModelImport);

class PatientApplicationListClass extends BaseListPage {

    state: ApplicationListType = {
        ...ApplicationList,
        btnQ: {}
    };
    private DHospitalBookingModelResult: any = null;
    private importResult: any = null;
    private EditOpen: number[] = [101, 102, 103, 104, 105];

    componentDidMount() {

        super.componentDidMount();
        this.loadHospital();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.dentistry.service_user.admin_list}?page=${searchData.page}&size=${pageSize}`;
        url = url + addParams("name", searchData.name, STRING_STR);
        url = url + addParams("phone", searchData.phone, STRING_STR);
        url = url + addParams("idCard", searchData.idCard, STRING_STR);
        url = url + addParams("status", searchData.status, NUMBER_STR);
        url = url + addParams("hospitalId", searchData.hospitalId, NUMBER_STR);
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };

    loadHospital() {

        // this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.dhHospital.find_select_data}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(BaseDhPageDate.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    goCreate = () => {
        window.location.href = "/#/dh/patient_application/add"
    }

    provinceDownloadExcel = () => {
        this.setState({
            importLoading: true,
            tip: "数据导出中"
        });
        Fetch.get(
            `${API.dentistry.service_user.audit_download}`
        ).then((data) => {
            if (data) {
                const currentTimeStamp = Math.floor(Date.now() / 1000);
                common.downloadFile("“乐龄互助·口腔守护”待审核名单" + currentTimeStamp + ".xlsx", API.domain + data, (callback: any) => {
                    if (callback === true) {
                        message.success('处理完成');
                    } else {
                        message.error('下载失败，请联系管理员');
                    }
                    this.setState({
                        importLoading: false,
                    });
                });
            } else {
                message.error('下载失败，请联系管理员');
                this.setState({
                    importLoading: false,
                });
            }
        }).finally(() => {
            this.setState({
                importLoading: false,
            });
        });
    };

    clickFileInput = () => {
        // @ts-ignore
        document.getElementById('fileInput').click()
    }

    onImportExcel = (file: any) => {
        this.setState({
            importLoading: true,
            tip: "数据导入中"
        });
        // 获取上传的文件对象
        const {files} = file.target;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                // @ts-ignore
                const {result} = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, {type: 'binary'});
                let data: any = []; // 存储获取到的数据
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break; // 如果只取第一张表，就取消注释这行
                    }
                }
                const param = this.buildData(data);
                Fetch.postJSON(
                    `${API.dentistry.service_user.file_import_audit}`, param
                ).then((data) => {
                        const currentTimeStamp = Math.floor(Date.now() / 1000);
                        data.fileName = "“情暖老兵·牙科”导入失败名单" + currentTimeStamp + ".xlsx";
                        this.setState(
                            {
                                importResultData: data
                            },
                            this.importResult.show
                        );
                    }
                );
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                this.setState({
                    importLoading: false,
                });
                return;
            } finally {

            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
        //TODO 处理完成后刷新页面
    }

    buildData = (dates: any) => {

        const result = [];
        for (let i = 0; i < dates.length; i++) {
            const item = dates[i];
            result.push({
                name: item.姓名,
                idCard: item.身份证号,
                resideCity: item.居住城市,
                warVeteran: item.是否是老兵本人,
                warVeteranName: item.老兵姓名,
                warVeteranIdNumber: item.老兵身份证号,
                veteranRelations: item.申请人与老兵关系,
                veteran: item.校验结果,
            })
        }
        return result;
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    editBooking = (id: number) => {
        this.setState({
            selectId: id
        }, () => {
            this.DHospitalBookingModelResult.show();
        })
    }

    auditConfirm = (id: number) => {

        const that = this;
        confirm({
            title: `是否审核通过？`,
            onOk() {
                let url = API.dentistry.service_user_status.update_patient_approved;
                Fetch.putJSON(url, {serviceId: id}).then((data) => {
                    message.success("操作成功")
                    that.loadData();
                })
            }
        });
    }

    auditCancel = (id: number) => {

        const that = this;
        confirm({
            title: `是否审核退回？`,
            onOk() {
                let url = API.dentistry.service_user_status.update_patient_review_failed;
                Fetch.putJSON(url, {serviceId: id}).then((data) => {
                    message.success("操作成功")
                    that.loadData();
                })
            }
        });
    }

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content,
            modalTitle,
            selectId,
            hospitalSelect
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}
                        </span>
                            {!record.accountRefine ? (
                                <Tooltip placement="top" title={"账户信息待完善"}>
                                    <InfoCircleOutlined className={styles.warnIcon}/>
                                    <HomeOutlined/>
                                </Tooltip>
                            ) : ('')}
                        </div>
                    );
                },
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{genderArr[value]}</span>
                    );
                },
            },
            {
                title: '年龄',
                dataIndex: 'age',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '身份证号码',
                dataIndex: 'idNumber',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '关联医院',
                dataIndex: 'hospitalName',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'dhStatus',
                align: `center`,
                render(value: any, record: any) {
                    return <>
                        <div className={styles.statusDiv}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    color: dhStatusColorObj[value]
                                }}>{dhStatusObj[value]}</div>
                            <Popover placement="left" title={record.name + "的操作记录"}
                                     content={<DhRecordOperationsDetail serviceId={record.id}/>}>
                                {value === 113 || value === 116 ? (
                                    <InfoCircleOutlined className={styles.warnIcon}/>
                                ) : (
                                    <DatabaseOutlined className={styles.recordIcon}/>
                                )}
                            </Popover>
                        </div>
                    </>;
                },
            },
            {
                title: '预约时间',
                dataIndex: 'bookingDate',
                align: `center`,
                render(value: any) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            }, {
                title: '上午&下午',
                dataIndex: 'bookingTime',
                align: `center`,
                render(value: any) {
                    return <span style={{wordBreak: 'break-all'}}>{BookingTime[value]}</span>;
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
                render(value: any) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            {record.dhStatus === 102 && (
                                <Popconfirm
                                    placement="top"
                                    title={"审核"}
                                    description={"是否审核通过"}
                                    okText="通过"
                                    cancelText="不通过"
                                    onConfirm={() => this.auditConfirm(record.id)}
                                    onCancel={() => this.auditCancel(record.id)}
                                >
                                    <Button type={"link"}>审核</Button>
                                </Popconfirm>
                            )}
                            {(btnQ.ROLE_140004 && this.EditOpen.includes(record.dhStatus)) && (
                                <Link to={`/dh/patient_application/edit?id=${encryptData(record.id + "")}`}>
                                    编辑
                                </Link>
                            )}
                            {/*TODO 当患者没有分配时有编辑按钮 没有分配按钮*/}
                            {(btnQ.ROLE_140006 && record.dhStatus === 101) && (
                                <Link to={`/dh/patient_application/booking?id=${encryptData(record.id + "")}`}>
                                    预约
                                </Link>
                            )}
                            {(btnQ.ROLE_140007 && (record.dhStatus === 104 || record.dhStatus === 102)) && (
                                <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                        onClick={() => this.editBooking(record.bookingId)}>
                                    改约
                                </Button>
                            )}
                            {btnQ.ROLE_140005 && (
                                <Link to={`/dh/patient_application/detail?id=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            )}
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction="vertical" style={{width: '100%'}}>
                            <Space onKeyDown={this.handleKeyDown} wrap>
                                <Input
                                    placeholder="姓名"
                                    value={searchData.name}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, name: e.target.value})
                                    }
                                    }
                                />
                                <Input
                                    placeholder="手机号"
                                    value={searchData.phone}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, phone: e.target.value})
                                    }
                                    }
                                />
                                <Input
                                    placeholder="身份证号"
                                    value={searchData.idCard}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, idCard: e.target.value})
                                    }
                                    }
                                />
                                <Select
                                    style={{width: 220}}
                                    placeholder="状态"
                                    optionFilterProp="children"
                                    options={dhStatusArray}
                                    value={searchData.status}
                                    allowClear={true}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, status: e})
                                    }}
                                />
                                <Select
                                    showSearch
                                    placeholder="选择执行机构名称"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, hospitalId: e})
                                    }}
                                    value={searchData.hospitalId}
                                    filterOption={(input: any, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{width: 200}}
                                    options={hospitalSelect}
                                />

                                <Button onClick={this.loadData}>
                                    搜索
                                </Button>
                                <Button onClick={() => this.reset()}>重置</Button>
                            </Space>
                            <div style={{float: 'right', marginLeft: 'auto'}}>

                                <Space className={styles.optMenu}>
                                    {(btnQ.ROLE_140001 || btnQ.ROLE_140008) && (
                                        <Button
                                            onClick={this.goCreate}
                                            type="primary"
                                            icon={<PlusOutlined/>}
                                        >
                                            添加患者
                                        </Button>
                                    )}
                                    {btnQ.ROLE_140002 && (
                                        <Button
                                            onClick={this.provinceDownloadExcel}
                                            className={styles.addBtn}
                                        >
                                            导出待审核数据
                                        </Button>
                                    )}
                                    {btnQ.ROLE_140003 && (
                                        <Button
                                            onClick={this.clickFileInput}
                                        >
                                            导入已验真数据
                                        </Button>
                                    )}
                                </Space>
                            </div>
                        </Space>


                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <ImportResult
                    width={400}
                    title={modalTitle}
                    importResult={this.state.importResultData}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importResult = c)}
                />
                <DHospitalBookingModel
                    width={1300}
                    title={'预约医院'}
                    importResult={selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.DHospitalBookingModelResult = c)}
                />
                <input id="fileInput" className={styles.fileUploader} type='file'
                       accept='.xlsx, .xls'
                       onChange={this.onImportExcel}/>
            </>
        );
    }

}

export default function DhPatientApplicationList() {

    return (

        <App selectedKeys={
            ['DH_APPLICATION']
        }
             openKeys="ORAL_GUARD">
            <PatientApplicationListClass/>
        </App>
    )
}