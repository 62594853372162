import React from 'react';
import {Button, Divider, Space, Spin, Table} from "antd";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import BaseListPage from "../../common/listpage/BaseListPage";
import API from "../../common/api";
import {ColumnsType} from "antd/es/table";
import {BaseDhPageDate} from "../../dhHospital/common/DhPageCommon";
import App from '../../../App';
import {BasePageType} from "../../common/listpage/BaseListPageType";
import encryptData from "../../common/crypto";
import {Link} from "react-router-dom";

type User = { paperCode: string };
type ExamPaperListType = BasePageType & {}
export const DEFAULT: ExamPaperListType = {
    ...BaseDhPageDate,
}

class ExamPaperListClass extends BaseListPage {

    state: ExamPaperListType = {
        ...DEFAULT,
        btnQ: {}
    }

    componentDidMount() {

        super.componentDidMount();
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        let url = `${API.exam.exam_papers.exam_papers}?page=${searchData.page}&size=${pageSize}`;

        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                importLoading: false
            });
        })
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        super.reset(BaseDhPageDate.searchData);
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }


    render() {
        const {
            btnQ, searchData, content, pageSize, totalElements
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '试卷编码',
                dataIndex: 'paperCode',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}</span>
                        </div>
                    );
                },
            },
            {
                title: '试卷名称',
                dataIndex: 'paperTitle',
                align: `center`,
                render: (text: any, record: any) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{wordBreak: 'break-all'}}>{text}</span>
                        </div>
                    );
                },
            },

            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            <Link to={`/exam/ques/list?paperCode=${encryptData(record.paperCode + "")}`}>
                                试题列表
                            </Link>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction="vertical" style={{width: '100%'}}>
                            <Space onKeyDown={this.handleKeyDown} wrap>
                                {/*<Input*/}
                                {/*    placeholder="姓名"*/}
                                {/*    value={searchData.name}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, name: e.target.value})*/}
                                {/*    }*/}
                                {/*    }*/}
                                {/*/>*/}
                                {/*<Input*/}
                                {/*    placeholder="手机号"*/}
                                {/*    value={searchData.phone}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, phone: e.target.value})*/}
                                {/*    }*/}
                                {/*    }*/}
                                {/*/>*/}
                                {/*<Input*/}
                                {/*    placeholder="身份证号"*/}
                                {/*    value={searchData.idCard}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, idCard: e.target.value})*/}
                                {/*    }*/}
                                {/*    }*/}
                                {/*/>*/}
                                {/*<Select*/}
                                {/*    style={{width: 220}}*/}
                                {/*    placeholder="状态"*/}
                                {/*    optionFilterProp="children"*/}
                                {/*    options={dhStatusArray}*/}
                                {/*    value={searchData.status}*/}
                                {/*    allowClear={true}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, status: e})*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/*<Select*/}
                                {/*    showSearch*/}
                                {/*    placeholder="选择执行机构名称"*/}
                                {/*    optionFilterProp="children"*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.changeCondition({page: 0, hospitalId: e})*/}
                                {/*    }}*/}
                                {/*    value={searchData.hospitalId}*/}
                                {/*    filterOption={(input: any, option: any) =>*/}
                                {/*        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
                                {/*    }*/}
                                {/*    style={{width: 200}}*/}
                                {/*    options={hospitalSelect}*/}
                                {/*/>*/}

                                <Button onClick={this.loadData}>
                                    搜索
                                </Button>
                                <Button onClick={() => this.reset()}>重置</Button>
                            </Space>

                        </Space>


                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
            </>
        );
    }

}

export default function ExamPaperList() {

    return (

        <App selectedKeys={
            ['EXAM_PAPER_LIST']
        }
             openKeys="EXAM">
            <ExamPaperListClass/>
        </App>
    )
}
