import React, {Component} from 'react';
import {Breadcrumb, Button, Input, Space, Table} from 'antd';
import Fetch from '../common/FetchIt';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../App';
import OperationalActivityDetail from "./operationalActivityDetail";
import createModal from '../common/createModal';
// import ExpressFrom from './expressFrom';
import {PageData} from './types';
import styles from "../ad/ad.module.css";

type User = {};

const FormModal = createModal(OperationalActivityDetail);

class OperationalActivityListClass extends Component<{}, PageData> {
    state: PageData = {
        searchData: {
            name: "",
            age: 50,
        },
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        roleId: 0,
        orderId: 0,
        loading: true,
        statusArr: {
            1: '待开票',
            2: '已开票',
            3: '作废中',
            4: '重开中',
            5: '已上传',
        },
        status: 0,
        search: '',
        type: '',
        rowId: 0,
    };
    private modal: any = null;

    componentDidMount() {
        this.loadData();
    }


    changeCondition = (condition: any, load = false) => {

        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, name: "你好", age: 123},
            ...condition
        });
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    loadData = () => {
        const {page, pageSize, status, search, type} = this.state;
        Fetch.get(
            `${API.operationalActivity.operationalActivitiesList}?page=${page}&size=${pageSize}&status=${status}&type=${type}&search=${search}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };

    updateShow = (id: any) => {
        this.setState({
            rowId: id,
        })
        this.modal.show();
    }

    reset = () => {
        this.setState(
            {
                status: 0,
                search: '',
                type: '',
                page: 0,
            },
            this.loadData
        );
    };

    handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            this.loadData();
        }
    };

    create = (e: any) => {
        window.location.href = '/#/operationalActivity/edit';
    };

    render() {
        let that = this;
        const {page, pageSize, totalElements, content, statusArr} = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '小程序二维码',
                dataIndex: 'qrCode',
                align: `center`,
                width: 120,
                render: (text) => {
                    return (
                        <img
                            src={API.domain + text}
                            alt="封面图"
                            style={{width: '50px', height: '50px'}}
                        />
                    );
                },
            },
            {
                title: '编码',
                dataIndex: 'code',
                align: `center`,
                width: 120,
                render(value, record, index) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '名称',
                dataIndex: 'name',
                align: `center`,
                width: 120,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '按钮名称',
                dataIndex: 'buttonName',
                align: `center`,
                width: 120,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '跳转地址',
                dataIndex: 'bootstrapPage',
                align: `center`,
                width: 120,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '老用户提示',
                dataIndex: 'oldUsersEnter',
                align: `center`,
                width: 120,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value == true ? '提示' : '不提示'}</span>;
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
                width: 120,
                render(value) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                width: 65,
                render: (text, record: any) => {
                    return (
                        <Space>
                            <Link to={`/operationalActivity/edit?id=${record.id}`}>
                                修改
                            </Link>
                            <Link to={`/operationalActivity/detail?id=${record.id}`}>
                                详情
                            </Link>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>推广活动</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    {/* status: '', search: '', type: '', */}
                    <Space onKeyDown={this.handleKeyDown} wrap>
                        <Input
                            placeholder="请输入搜索内容"
                            value={this.state.search}
                            onChange={(e) => {
                                this.changeCondition({page: 0, search: e.target.value})
                            }
                            }
                        />
                        <Button onClick={this.loadData} type="primary">
                            搜索
                        </Button>
                        <Button onClick={this.reset}>重置</Button>
                    </Space>
                    <div className="mgTop15">
                        <Button
                            onClick={this.create}
                            className={styles.addBtn}
                            type="primary"
                        >
                            新增
                        </Button>
                    </div>
                    <Table
                        loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                    <FormModal
                        ref={(c) => (this.modal = c)}
                        id={this.state.rowId}
                        loadData={this.loadData}
                    />
                </div>
            </>
        );
    }
}

export default function OperationalActivityList() {
    return (
        <App selectedKeys={['operationalActivity']}>
            <OperationalActivityListClass/>
        </App>
    );
}
