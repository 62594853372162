import React, {Component} from 'react';
import 'dayjs/locale/zh-cn';
import {Breadcrumb, Button, Col, Input, Row, Space, Table,} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import App from '../../../App';
import {Link} from 'react-router-dom';

class InstitutionAuditListClass extends Component {
    modal = null;
    resetModal = null;
    state = {
        page: 0,
        pageSize: 10,
        totalElements: 0,
        totalPages: 0,
        content: [],
        name: '',
        date: [],
        institution: null,
        loading: true,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const {page, pageSize, name} = this.state;
        Fetch.get(
            `${API.institution.reviewed_ist}?page=${page}&size=${pageSize}&name=${name}`
        ).then((data) => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
                loading: false,
            });
        });
    };
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.loadData();
        }
    };

    changeCondition = (condition, load = false) => {
        this.setState(
            {
                ...condition,
            },
            () => {
                if (load) {
                    this.loadData();
                }
            }
        );
    };

    render() {
        const {page, pageSize, totalElements, content} = this.state;
        const columns = [
            {
                title: '组织名称',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '组织编码',
                dataIndex: 'code',
                align: `center`,
            },
            {
                title: '负责人',
                dataIndex: 'username',
                align: `center`,
            },
            {
                title: '负责人手机号',
                dataIndex: 'phone',
                align: `center`,
            },
            {
                title: '状态',
                dataIndex: 'auditStatus',
                align: `center`,
                render: (text) => {
                    return text === 1 ? '待审核' : '审核退回';
                },
            },
            {
                title: '操作',
                align: `center`,
                render: (text, record) => {
                    return (
                        <Space>
                            <Button>
                                <Link to={`/institution/reviewed_detail/${record.id}`}>查看</Link>
                            </Button>
                        </Space>
                    )
                },
            }
        ];

        return (
            <>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>组织审核</Breadcrumb.Item>
                </Breadcrumb>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Row>
                        <Col span={6}>
                        </Col>
                        <Col span={18} style={{textAlign: 'right'}}>
                            <Space onKeyDown={this.handleKeyDown} wrap>
                                组织名称：
                                <Input
                                    placeholder="请输入组织名称"
                                    value={this.state.name}
                                    onChange={(e) =>
                                        this.changeCondition({page: 0, name: e.target.value})
                                    }
                                />
                                <Button onClick={this.loadData}>搜索</Button>
                                <Button onClick={this.reset}>重置</Button>
                            </Space>
                        </Col>
                    </Row>

                    <Table
                        className="mgTop15"
                        bordered
                        loading={this.state.loading}
                        rowKey={(record) => {
                            return record.id;
                        }}
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination) =>
                            this.changeCondition(
                                {
                                    page: pagination.current - 1,
                                    pageSize: pagination.pageSize,
                                },
                                true
                            )
                        }
                    />
                </div>
            </>
        );
    }
}

export default function InstitutionAuditList() {
    return (
        <App selectedKeys={['InstitutionAuditList']} openKeys="InstitutionMenu">
            <InstitutionAuditListClass/>
        </App>
    );
}
