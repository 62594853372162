import React from 'react';
import {Button, Divider, Input, message, Modal, Popover, Select, Space, Spin, Table, Tooltip} from 'antd';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';
import 'dayjs/locale/zh-cn';
import App from '../../../App';
import {PageData} from '../util/types';
import {listDefaultData, selectStatus} from "../util/AierCommon";
import encryptData from '../../common/crypto';
import BaseListPage from "../../common/listpage/BaseListPage";
import {DatabaseOutlined, InfoCircleOutlined} from '@ant-design/icons';
import styles from './ServiceUserV2.module.css'
import RecordOperationsDetail from "../detail/RecordOperationsDetail";
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import createModal from "../../common/createModal";
import AccountsDifferentModal from "./AccountsDifferent";
import AierDownloadInformedConsentModal from "./AierDownloadInformedConsent";
import common from "../../common/common";
import AierDownloadInformedParams from "./AierDownloadInformedParams";

const ImportAccountsDifferentModal = createModal(AccountsDifferentModal);
const ImportAierDownloadInformedConsentModal = createModal(AierDownloadInformedConsentModal);
const ImportAierDownloadInformedParamsModal = createModal(AierDownloadInformedParams);

type TableType = {
    id: number,
    userId: number,
    name: string,
    gender: number,
    age: number,
    phone: string,
    idCard: string,
    status: number,
    hospitalName: string,
    createdDate: string,
    addFee: string,
    msg: string,
    paymentMethod: string
};
const {confirm} = Modal;

class ServiceUserAddListClass extends BaseListPage {
    state: PageData = {
        ...listDefaultData,
        btnQ: {
            ROLE_AIER_H_SCREENING: 0,
            ROLE_AIER_H_USER_AUDIT: 0,
            ROLE_AIER_H_SURGERY: 0,
            ROLE_AIER_H_VISIT: 0,
            ROLE_AIER_H_SUBMIT: 0,
            ROLE_AIER_H_SURGERY_INFO: 0,
            ROLE_AIER_H_DETAILS: 0,
            ROLE_AIER_H_R_F_AUDITS: 0,
            ROLE_81005: 0,
            ROLE_AIER_H_UPDATE_REFINE: 0,
            ROLE_AIER_EXIT_ACCOUNT: 0,
            ROLE_AIER_COMPLETE_ACCOUNT_SUBMIT: 0
        },
    };
    private importAccountsDifferent: any = null;
    private importAierDownloadInformedConsentModal: any = null;
    private importAierDownloadInformedParamsModal: any = null;

    componentDidMount() {
        super.componentDidMount()
        this.loadSelectData();
        this.loadHospital(null);
        selectStatus([202, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217], this);
    }

    loadData = () => {
        const {pageSize, searchData} = this.state;
        const hospitalId = searchData.hospitalId ? searchData.hospitalId : 0;
        const provinceId = searchData.provinceId ? searchData.provinceId : 0;
        let url = `${API.cooperateServiceUsersStatusV2.hospital_audit}?page=${searchData.page}&size=${pageSize}&name=${searchData.name}&phone=${searchData.phone}&idCard=${searchData.idCard}&status=${searchData.status}`;
        url = url + `&hospitalId=${hospitalId}&provinceId=${provinceId}`
        super.doLoadData(url, data => {
            this.setState({
                content: data.content,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            });
        })
    };


    updateHospitalSubmissions = (id: number, status: number) => {
        const that = this;
        const statusArr = [1, 202, 203, 204, 205]
        if (statusArr.indexOf(status) >= 0) {
            message.error('请先填写患者术后信息，然后提交');
            return false;
        }
        confirm({
            title: `是否提交患者信息到基金会审核？`,
            okText: `提交`,
            onOk() {
                Fetch.putJSON(API.cooperateServiceUsersStatusV2.update_hospital_submissions, {serviceUserId: id}).then((data) => {
                    if (data) {
                        if (data.code === "SC0020") {
                            that.openNotification();
                        } else if (data.code === "SC0026") {
                            that.setState({
                                twiceAccount: data.data,
                                selectId: id
                            }, () => {
                                that.importAccountsDifferent.show()
                            })
                        }
                    } else {
                        message.success('操作成功');
                        that.loadData();
                    }
                });
            },
        });
    }

    updateCompleteAccountSubmit = (id: number) => {
        const that = this;
        confirm({
            title: `是否提交患者信息到基金会审核？`,
            okText: `提交`,
            onOk() {
                Fetch.putJSON(API.cooperateServiceUsersStatusV2.update_complete_account_submit, {serviceUserId: id}).then(() => {
                    message.success('操作成功');
                    that.loadData();
                });
            },
        });
    }

    updateRevocationFinancialAudits = (id: number) => {
        const that = this;
        confirm({
            title: `是否撤回报销申请？`,
            okText: `确认撤回`,
            onOk() {
                Fetch.putJSON(API.cooperateServiceUsersStatusV2.update_revocation_financial_audits, {serviceUserId: id}).then(() => {
                    message.success('操作成功');
                    that.loadData();
                });
            },
        });
    }

    loadSelectData = () => {
        let url = `${API.zone.find_zone_by_pid}?parentId=0&r=1`;
        Fetch.get(url).then((data) => {

            let resultList = [];
            for (let dataKey in data) {
                resultList.push({
                    value: data[dataKey].id,
                    label: data[dataKey].name
                })
            }
            this.setState({
                zoneSelect: resultList
            });
        });

    };

    loadHospital(provId: any) {
        if (provId === undefined) {
            provId = null
        }
        this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.hospital_area.find_select_data}?provId=${provId}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };

    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
        if (condition.provinceId) {
            this.loadHospital(condition.provinceId)
        }
    };

    reset = () => {
        super.reset(listDefaultData.searchData);
        this.loadHospital(null);
    };

    closeNotification = () => {
        console.log(
            'Notification was closed. Either the close button was clicked or duration time elapsed.',
        );
    };

    openNotification = () => {
        confirm({
            title: `需要完善账户信息才能完成报销`,
            okText: `去完善`,
            onOk() {
                window.location.href = '/#/hospitalAccount/edit';
            }
        })
    };

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    // openAierDownloadInformedConsent = () => {
    //
    //     this.importAierDownloadInformedConsentModal.show();
    // }

    //1 待审核,202 审核通过,204 符合手术条件,206 填写了报销信息,208 医院撤回报销,210 财务审核退回
    downloadAierLetterCommitmentPdf = (item: TableType) => {

        if (item.status === 206 || item.status === 208 || item.status === 210) {
            this.setState({
                tip: "下载中...",
                importLoading: true
            }, () => {
                Fetch.postJSON(`${API.cooperateServiceUsersStatusV2.download_informed_consent}`, {serviceId: item.id}).then((data) => {
                    common.downloadFile2(data.fileName, API.domain + data.url)
                        .then(() => {
                            this.setState({importLoading: false})
                            message.success("下载完成")
                        })
                        .catch(() => {
                            this.setState({importLoading: false})
                            message.error("部分文件下载失败，请联系管理员。")
                        });
                });
            })
        } else if (item.status === 1 || item.status === 202 || item.status === 204) {
            this.setState({
                selectId: item.id,
                modalTitle: "请选择患者" + item.name + "的眼病及眼别"
            }, () => {
                this.importAierDownloadInformedParamsModal.show();
            })
        } else {
            message.info("状态不符无法操作")
        }
    }

    render() {
        const {
            pageSize, totalElements,
            content, statusArr, genderArr, btnQ, statusListArr,
            statusColorArr, searchData
        } = this.state;
        const columns: ColumnsType<TableType> = [

            {
                title: '姓名',
                dataIndex: 'name',
                align: `center`,
            },
            {
                title: '性别',
                dataIndex: 'gender',
                align: `center`,
                // width: 30,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{genderArr[value]}</span>
                    );
                },
            },
            {
                title: '年龄',
                dataIndex: 'age',
                align: `center`,
                // width: 30,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                align: `center`,
                // width: 70,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '身份证号码',
                dataIndex: 'idCard',
                align: `center`,
                // width: 100,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '收款方式',
                dataIndex: 'paymentMethod',
                align: `center`,
                // width: 80,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '关联医院',
                dataIndex: 'hospitalName',
                align: `center`,
                // width: 100,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '状态',
                dataIndex: 'status',
                align: `center`,
                // width: 100,
                render(value: any, record: any) {
                    return (
                        <>
                            <div className={styles.statusDiv}>
                                <div
                                    style={{
                                        wordBreak: 'break-all',
                                        color: statusColorArr[value]
                                    }}>{statusArr[value]}</div>
                                <Popover placement="left" title={record.name + "的操作记录"}
                                         content={<RecordOperationsDetail serviceId={record.id}/>}>
                                    <DatabaseOutlined className={styles.recordIcon}/>
                                </Popover>
                                {value === 210 || value === 214 ? (
                                    <>
                                        <Tooltip placement="top" title={record.msg ? record.msg : "未填写原因"}>
                                            <InfoCircleOutlined className={styles.warnIcon}/>
                                        </Tooltip>

                                    </>
                                ) : ''}
                            </div>
                        </>
                    )
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createdDate',
                align: `center`,
                // width: 100,
                render(value: any) {
                    return <span style={{wordBreak: 'break-all'}}>{value}</span>;
                },
            },
            {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                // width: 120,
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>} size={2}>
                            {btnQ.ROLE_AIER_H_SCREENING &&
                                (record.status == 202 || record.status == 204 || record.status == 205) && (
                                    <Link to={`/hospitalAuditListV2/screening?id=${encryptData(record.id + "")}`}>
                                        检查
                                    </Link>
                                )}
                            {btnQ.ROLE_AIER_H_SURGERY_INFO &&
                                (record.status == 204 || record.status == 206 || record.status == 208 || record.status == 210) && (
                                    <>
                                        {(record.status == 206 || record.status == 208 || record.status == 210) && btnQ.ROLE_AIER_H_SUBMIT && (
                                            <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                                    onClick={() => this.updateHospitalSubmissions(record.id, record.status)}>
                                                提交审核
                                            </Button>
                                        )}
                                        {btnQ.ROLE_AIER_H_SURGERY_INFO && (
                                            <Link
                                                to={`/hospitalAuditListV2/postoperativeInfo?id=${encryptData(record.id + "")}`}>
                                                术后信息
                                            </Link>
                                        )}
                                    </>
                                )}
                            {btnQ.ROLE_AIER_COMPLETE_ACCOUNT_SUBMIT && record.status == 215 && (
                                <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                        onClick={() => this.updateCompleteAccountSubmit(record.id)}>
                                    提交报销
                                </Button>
                            )}
                            {btnQ.ROLE_AIER_EXIT_ACCOUNT &&
                                (record.status == 214 ||
                                    record.status == 215 ||
                                    record.status == 202 ||
                                    record.status == 204 ||
                                    record.status == 206 ||
                                    record.status == 208 ||
                                    record.status == 210)
                                && (
                                    <Link
                                        to={`/hospitalAuditListV2/editPatientAccount?id=${encryptData(record.id + "")}`}>
                                        编辑
                                    </Link>
                                )}
                            {(btnQ.ROLE_AIER_H_R_F_AUDITS && record.status == 207) && (
                                <Button className={styles.antBtnPadding} type="link" danger size={"small"}
                                        onClick={() => this.updateRevocationFinancialAudits(record.id)}>
                                    撤回申请
                                </Button>
                            )}
                            {btnQ.ROLE_AIER_H_UPDATE_REFINE && record.status == 211 && (
                                <Link to={`/hospitalAuditListV2/refine?id=${encryptData(record.id + "")}`}>
                                    完善信息
                                </Link>
                            )}
                            {btnQ.ROLE_AIER_H_DETAILS && (
                                <Link to={`/serviceUserDetail/detail?id=${encryptData(record.id + "")}`}>
                                    详情
                                </Link>
                            )}
                            {btnQ.ROLE_81005 &&
                                (record.status == 1 ||
                                    record.status == 202 ||
                                    record.status == 204 ||
                                    record.status == 206 ||
                                    record.status == 208 ||
                                    record.status == 210)
                                && (
                                    <Button className={styles.antBtnPadding} type="link" size={"small"}
                                            onClick={() => this.downloadAierLetterCommitmentPdf(record)}>
                                        下载
                                    </Button>
                                )}
                        </Space>
                    );
                }
                ,
            },
        ];

        return (
            <>
                {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                {/*    <Breadcrumb.Item>患者名单</Breadcrumb.Item>*/}
                {/*</Breadcrumb>*/}
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    {/* status: '', search: '', type: '', */}
                    <Space onKeyDown={this.handleKeyDown} wrap>
                        {sessionStorage.orgName === '管理中心' ? (
                            <>
                                <Select
                                    showSearch
                                    placeholder="选择省份"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, provinceId: e})
                                    }}
                                    value={searchData.provinceId}
                                    filterOption={(input: any, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{width: 120}}
                                    options={this.state.zoneSelect}
                                />
                                <Select
                                    showSearch
                                    placeholder="选择执行机构名称"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, hospitalId: e})
                                    }}
                                    value={searchData.hospitalId}
                                    filterOption={(input: any, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    style={{width: 200}}
                                    options={this.state.hospitalSelect}
                                />
                            </>
                        ) : ('')}
                        <Input
                            placeholder="姓名"
                            value={searchData.name}
                            onChange={(e) => {
                                this.changeCondition({page: 0, name: e.target.value})
                            }
                            }
                        />
                        <Input
                            placeholder="手机号"
                            value={searchData.phone}
                            onChange={(e) => {
                                this.changeCondition({page: 0, phone: e.target.value})
                            }
                            }
                        />
                        <Input
                            placeholder="身份证号"
                            value={searchData.idCard}
                            onChange={(e) => {
                                this.changeCondition({page: 0, idCard: e.target.value})
                            }
                            }
                        />
                        <Select
                            style={{width: 220}}
                            placeholder="状态"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            value={searchData.status}
                            options={statusListArr}
                            onChange={(e) => {
                                this.changeCondition({page: 0, status: e})
                            }}
                        />
                        <Button onClick={this.loadData}>
                            搜索
                        </Button>
                        <Button onClick={() => this.reset()}>重置</Button>
                    </Space>
                        {/*<Space style={{float: "right"}}>*/}
                        {/*    <Button*/}
                        {/*        onClick={this.openAierDownloadInformedConsent}*/}
                        {/*    >*/}
                        {/*        下载*/}
                        {/*    </Button>*/}
                        {/*</Space>*/}
                    <Table
                        // loading={this.state.loading}
                        className="mgTop15"
                        bordered
                        rowKey="id"
                        columns={columns}
                        dataSource={content}
                        pagination={{
                            current: searchData.page + 1,
                            pageSize,
                            total: totalElements,
                            showTotal: (total) => `共${total}条`,
                            showSizeChanger: false,
                        }}
                        onChange={(pagination: any) =>
                            this.pageChange(
                                {
                                    page: pagination.current - 1
                                }
                            )
                        }
                    />
                    </Spin>
                </div>
                <ImportAccountsDifferentModal
                    width={1200}
                    title={'提示:患者本次报名与上次报名账户信息不同，请确认'}
                    importResult={this.state.twiceAccount}
                    selectId={this.state.selectId}
                    onCancel={this.closeModal}
                    ref={(c) => (this.importAccountsDifferent = c)}
                />
                {/*<ImportAierDownloadInformedConsentModal*/}
                {/*    width={1200}*/}
                {/*    title={'选择需要下载的患者'}*/}
                {/*    onCancel={this.closeModal}*/}
                {/*    ref={(c) => (this.importAierDownloadInformedConsentModal = c)}*/}
                {/*/>*/}
                <ImportAierDownloadInformedParamsModal
                    width={700}
                    title={this.state.modalTitle}
                    onCancel={this.closeModal}
                    serviceId={this.state.selectId}
                    ref={(c) => (this.importAierDownloadInformedParamsModal = c)}
                />

            </>
        );
    }
}

export default function ServiceUserHospitalV2List() {
    return (
        <App selectedKeys={['hospitalAuditList']} openKeys="aierOphthalmology">
            <ServiceUserAddListClass/>
        </App>
    );
}
