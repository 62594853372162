import React, {Component} from 'react';
import {Button, Card, Descriptions, Image, Space, Spin,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {decryptData} from "../../common/crypto";

import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import {getInitDetail, TroupeArtistDetailType} from "../util/TroupeArtistType";
import {genderArr} from "../../cooperate/util/AierCommon";
import {buildTroupFileData} from "../util/TroupeArtistUtil";


class TroupArtistGroupDetailClass extends Component<{}, TroupeArtistDetailType> {

    state: TroupeArtistDetailType = {
        ...getInitDetail()
    };

    componentDidMount() {
        this.setState({
            id: decryptData(common.getQuery('id')),
        }, () => {
            this.getData()
        })
    };

    getData() {

        const {id} = this.state;
        let url = `${API.troup.guru.get_admin_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            buildTroupFileData(data)
            this.setState({
                data: data,
                importLoading: false
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    render() {
        const {data} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                            {data ? (
                                <>
                                    <Card size="small"
                                          title={`基本信息`}>
                                        <Space direction="vertical">
                                            <>
                                                <Descriptions>
                                                    {data.name && (
                                                        <Descriptions.Item label="姓名">
                                                            {data.name}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.gender && (
                                                        <Descriptions.Item label="性别">
                                                            {genderArr[data.gender]}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.age && (
                                                        <Descriptions.Item label="年龄">
                                                            {data.age}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.birthday && (
                                                        <Descriptions.Item label="出生日期">
                                                            {data.birthday}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.address && (
                                                        <Descriptions.Item label="现居地址">
                                                            {data.address}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.origin && (
                                                        <Descriptions.Item label="籍贯">
                                                            {data.origin}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.idNumber && (
                                                        <Descriptions.Item label="身份证号">
                                                            {data.idNumber}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.phone && (
                                                        <Descriptions.Item label="联系电话">
                                                            {data.phone}
                                                        </Descriptions.Item>
                                                    )}
                                                    {data.groupName && (
                                                        <Descriptions.Item label="所在团队">
                                                            {data.groupName}
                                                        </Descriptions.Item>
                                                    )}
                                                </Descriptions>
                                            </>
                                        </Space>
                                    </Card>
                                    <Card size="small"
                                          title={`其他信息`}>
                                        <Space direction="vertical">
                                            <Descriptions>
                                                {data.ethnic && (
                                                    <Descriptions.Item label="民族">
                                                        {data.ethnic}
                                                    </Descriptions.Item>
                                                )}
                                                {data.politics && (
                                                    <Descriptions.Item label="政治面貌">
                                                        {data.politics}
                                                    </Descriptions.Item>
                                                )}
                                                {data.specialtyStr && (
                                                    <Descriptions.Item label="兴趣爱好">
                                                        {data.specialtyStr}
                                                    </Descriptions.Item>
                                                )}
                                                {data.groupDuties && (
                                                    <Descriptions.Item label="团内职务">
                                                        {data.groupDuties}
                                                    </Descriptions.Item>
                                                )}
                                                {data.originalUnit && (
                                                    <Descriptions.Item label="原单位">
                                                        {data.originalUnit}
                                                    </Descriptions.Item>
                                                )}
                                                {data.originalDuties && (
                                                    <Descriptions.Item label="原职务">
                                                        {data.originalDuties}
                                                    </Descriptions.Item>
                                                )}
                                            </Descriptions>
                                        </Space>
                                    </Card>
                                    <Card size="small"
                                          title={`签名信息`}>
                                        <Space direction="vertical">
                                            <>
                                                <Descriptions>
                                                    <Descriptions.Item label="签名时间">
                                                        {data.signDate}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="签名照片">
                                                        <Image
                                                            width={100}
                                                            src={data.signUrl?.url}
                                                        />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </>
                                        </Space>
                                    </Card>
                                    <Card size="small"
                                          title={`证书信息`}>
                                        <Space direction="vertical">
                                            <>
                                                <Descriptions>
                                                    <Descriptions.Item label="发证时间">
                                                        {data.certificateDate}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="证书">
                                                        <Image
                                                            width={100}
                                                            src={data.certificate?.url}
                                                        />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </>
                                        </Space>
                                    </Card>
                                </>
                            ) : ('')}
                            <Card>
                                <Space>
                                    <Button
                                        // type="primary"
                                        size="large"
                                        onClick={this.cancel}
                                    >
                                        返回
                                    </Button>
                                </Space>
                            </Card>
                        </Space>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function TroupArtistGroupDetail() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <TroupArtistGroupDetailClass/>
        </App>
    );
}
