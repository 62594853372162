import React from 'react';
import {Button, DatePicker, Divider, Input, Space, Spin, Table} from 'antd';
import API from '../common/api';
import {ColumnsType} from 'antd/es/table';
import 'dayjs/locale/zh-cn';
import App from '../../App';
import BaseListPage from "../common/listpage/BaseListPage";
import BreadcrumbInfo from "../common/breadcrumb/BreadcrumbInfo";
import Fetch from "../common/FetchIt";
import {initTaxesList, initTaxesListData, TaxesListType} from "./TaxesType";
import {addParams, STRING_STR} from "../cooperate/bill/page/ListCommon";
import TaxesDownloadModal from "./TaxesDownloadModal";
import createModal from "../common/createModal";
import {Link} from "react-router-dom";
import encryptData from "../common/crypto";
import dayjs from "dayjs";


type User = {
    accountRefine: boolean,
    name: string,
    id: number,
    taxesUserId: number | 0
};
const ImportTaxesDownloadModal = createModal(TaxesDownloadModal);

class TaxesListClass extends BaseListPage {

    state: TaxesListType = {
        ...initTaxesListData,
        btnQ: {}
    };
    private taxesDownloadModalRef: any = null;

    componentDidMount() {
        super.componentDidMount();
        this.loadHospital();
    }

    loadData = () => {
        this.setState({
            importLoading: true
        }, () => {
            const {pageSize, searchData} = this.state;
            let url = `${API.taxes_user.query_page}?page=${searchData.page}&size=${pageSize}`;
            if (searchData.taxesDate) {
                url = url + addParams("taxesDate", searchData.taxesDate.format('YYYY-MM-DD'), STRING_STR);
            }
            url = url + addParams("name", searchData.name, STRING_STR);
            url = url + addParams("idNumber", searchData.idNumber, STRING_STR);
            super.doLoadData(url, data => {
                this.setState({
                    content: data.content,
                    totalElements: data.totalElements,
                    totalPages: data.totalPages,
                    importLoading: false
                }, () => {
                });
            })
        })
    };

    loadHospital() {

        // this.state.searchData.hospitalId = undefined
        Fetch.get(`${API.dhHospital.find_select_data}`).then((data) => {
            this.setState({
                hospitalSelect: data
            });
        });
    };


    changeCondition = (condition: any) => {
        const {searchData} = this.state;
        this.setState({
            searchData: {...searchData, ...condition},
        });
    };

    reset = () => {
        this.setState({
            content: []
        }, () => {
            super.reset(initTaxesList().searchData);
        })
    };

    handleKeyDown = (e: any) => {
        super.handleKeyDown(e);
    }

    closeModal = () => {
        this.setState({
                importLoading: false,
            }, () => {
                this.loadData();
            }
        );
    }

    showDownloadModal = () => {

        this.taxesDownloadModalRef.show();
    }

    render() {
        const {
            pageSize, totalElements,
            btnQ, searchData, content,
            selectId,
        } = this.state;
        const columns: ColumnsType<User> = [

            {
                title: '姓名',
                dataIndex: 'userName',
                align: `center`,
                render: (value: any, record: any) => {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '身份证号',
                dataIndex: 'idNumber',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '活动名称',
                dataIndex: 'activityName',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '联系电话',
                dataIndex: 'phone',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            },
            {
                title: '报税日期',
                dataIndex: 'taxes',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '金额',
                dataIndex: 'account',
                align: `center`,
                render(value: any) {
                    return (
                        <span style={{wordBreak: 'break-all'}}>{value}</span>
                    );
                },
            }, {
                title: '操作',
                align: `center`,
                dataIndex: 'status',
                render: (text: any, record: any) => {
                    return (
                        <Space split={<Divider type="vertical"/>}>
                            <Link to={`/taxes/detail?id=${encryptData(record.taxesUserId + "")}`}>
                                详情
                            </Link>
                        </Space>
                    );
                },
            },
        ];

        return (
            <>
                <Spin tip={this.state.tip} spinning={this.state.importLoading}>
                    <BreadcrumbInfo/>
                    <div
                        className="site-layout-background"
                        style={{padding: 24, minHeight: 720}}
                    >
                        <Space direction="vertical" style={{width: '100%'}}>
                            <Space onKeyDown={this.handleKeyDown} wrap>
                                <DatePicker
                                    value={searchData.taxesDate ? searchData.taxesDate : undefined}
                                    onChange={(e) => {
                                        if (e) {
                                            this.changeCondition({page: 0, taxesDate: e.date(10)})
                                        } else {
                                            this.changeCondition({page: 0, taxesDate: dayjs()})
                                        }
                                    }}
                                    inputReadOnly={true} picker="month"/>
                                <Input
                                    placeholder="姓名"
                                    value={searchData.name}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, name: e.target.value})
                                    }
                                    }
                                />

                                <Input
                                    placeholder="身份证号"
                                    value={searchData.idNumber}
                                    onChange={(e) => {
                                        this.changeCondition({page: 0, idNumber: e.target.value})
                                    }
                                    }
                                />
                                <Button onClick={this.loadData}>
                                    搜索
                                </Button>
                                <Button onClick={() => this.reset()}>重置</Button>
                                <Button onClick={() => this.showDownloadModal()}>下载</Button>
                            </Space>
                            <div style={{float: 'right', marginLeft: 'auto'}}>

                            </div>
                        </Space>


                        <Table
                            // loading={this.state.loading}
                            className="mgTop15"
                            bordered
                            rowKey="id"
                            columns={columns}
                            dataSource={content}
                            pagination={{
                                current: searchData.page + 1,
                                pageSize,
                                total: totalElements,
                                showTotal: (total) => `共${total}条`,
                                showSizeChanger: false,
                            }}
                            onChange={(pagination: any) =>
                                this.pageChange(
                                    {
                                        page: pagination.current - 1
                                    }
                                )
                            }
                        />
                    </div>
                </Spin>
                <ImportTaxesDownloadModal
                    width={500}
                    title={"下载报税文件"}
                    onCancel={this.closeModal}
                    ref={(c) => (this.taxesDownloadModalRef = c)}
                />
            </>
        );
    }

}

export default function TaxesList() {

    return (

        <App selectedKeys={
            ['TAXES_LIST']
        }>
            <TaxesListClass/>
        </App>
    )
}